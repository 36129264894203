.clue {
    width: 400px;
    height: 300px;
    border-radius: 5px;
    background-color: #EFEFE6;
    border-radius: 10px;
    position: relative;
    color: #aaa;
    margin-left: 40px;
}

.riddle-question {
    font-size: 20px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    color: white;
    font-style: italic;
    font-weight: 250;
    margin-top: 5px;
    background-color: black;
    padding: 5px 10px;
    border-radius: 0px 0px 10px 10px;
    width: 110px;
}

.clue-0,
.clue-1,
.clue-2 {
    background: linear-gradient(to bottom, #ffc1ff, #DDA0DD);
}

.clue-3,
.clue-4,
.clue-5 {
    background: linear-gradient(to bottom, #ffafa6, #FA8072);
}

.clue-6,
.clue-7,
.clue-8 {
    background: linear-gradient(to bottom, #afddff, #6aa2cd);
}

.clue-9,
.clue-10,
.clue-11 {
    background: linear-gradient(to bottom, #92fa9d, #71BC78);
}

.first-card {
    background: linear-gradient(in oklab, white, black)
}

.clue-text {
    color: white;
    font-weight: 700;
    margin-top: 30px;
    font-size: 17px;
    cursor: pointer !important;
}

.clue-value {
    font-size: 13px;
    position: absolute;
    right: 0px;
    text-align: center;
    margin-top: -4px;
    margin-right: 0px;
    font-weight: 700;
    background-color: #EFEFE6;
    border-radius: 0px 0px 5px 0px;
    width: 15px;
    height: 15px;
    cursor: pointer !important;
}

.clue-text-black {
    color: black;
    font-weight: 700;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer !important;
}

.answers {
    font-size: 13px;
    font-weight: 300;
    cursor: pointer !important;
}

.first-card-text {
    font-size: 40px;
    font-weight: 700;
    padding: 0px 5px;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer !important;
    color: white;
}

.front-card-clue-text {
    font-size: 27px;
    font-weight: 700;
    padding: 0px 5px;
    position: relative;
    transform: translateY(-50%);
    cursor: pointer !important;
    color: black;
    padding: 15px;
    font-weight: 700;
    margin-top: 0;
    top: 50%;
}

.front {
    top: 30% !important
}

.clue-text-directions {
    font-size: 15px;
    font-weight: 300;
    position: relative;
    color: black;
    top: 130px;
}

.rotate-icon {
    color: black;
    font-size: 17px;
    position: absolute;
    right: 15px;
    bottom: 10px;
    cursor: pointer;
}






.flip-card-clue {
    background-color: transparent;
    perspective: 1000px;
    width: 400px;
    height: 300px;
    border-radius: 5px;
    border-radius: 20px;
    position: relative;
    color: #aaa;
    margin-left: 40px;
}

.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border-radius: 20px;
}

.flip-card-clue:hover .flip-card-inner {
    transform: rotateY(180deg);
}

/* .flip-card-clue:active {
    transform: rotateY(180deg);
} */

.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
}

.flip-card-front {
    background-color: #bbb;
    color: black;
}

.flip-card-back {
    background-color: white;
    color: black;
    transform: rotateY(180deg);
}

.white-text {
    color: black;
}

@media (max-height: 660px) {
    .clue {
        height: 220px;
    }

    .clue-text {
        font-size: 15px;
    }

    .first-card-text {
        font-size: 35px;
    }
}


@media (max-width: 430px) {
    .clue {
        width: 350px;
        height: 280px;
        border-radius: 20px;
        margin-top: 0px;
        margin-left: 12px;
        position: relative;
    }

    .flip-card-clue {
        background-color: transparent;
        perspective: 1000px;
        width: 350px;
        height: 280px;
        border-radius: 5px;
        border-radius: 20px;
        position: relative;
        color: #aaa;
        margin-left: 12px;
        position: relative;
    }

    .flip-card-clue:hover .flip-card-inner {
        transform: none
    }

    .clue-text {
        font-size: 25px;
    }

    .clue-text-black {
        font-size: 25px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
    }

    .front-card-clue-text {
        font-size: 24px;
        overflow-x: scroll;
        letter-spacing: -.05ch;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        padding: 10px 15px;
        line-height: 2.2ch;
    }

    .swipe-instructions-text {
        color: black;
        bottom: -10px;
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        font-size: 15px;
        font-weight: 350;
    }
}

@media (max-height: 630px) {
    .clue {
        height: 240px;
    }

    .clue-text {
        font-size: 15px;
    }

    .first-card-text {
        font-size: 35px;
    }
}
.game-header {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
    padding-bottom: 10px;
    margin-top: -50px;
}

.game-header-name {
    font-family: "nyt-karnakcondensed";
    font-size: 50px;
    font-weight: bolder;
    text-align: left;
    margin-left: 10vw;
    margin-bottom: 0px;
    margin-top: 65px;
    color: white;

}

.game-date {
    font-size: 28px;
    font-weight: 250;
    margin-top: 81px;
    margin-left: 25px;
    color: white
}

.beta {
    font-size: 25px;
    font-weight: 250;
    margin-top: 82px;
    margin-left: 25px;
    color: white;
    cursor: pointer;
}

.play-newest-puzzle-button {
    padding: 8px 20px;
    border-radius: 10px;
    background-color: gold;
    border: none;
    color: black;
    font-size: 16px;
    margin-left: 20px;
    cursor: pointer;
    margin-top: 1px;
    font-weight: 300;
    position: absolute;
}

.flex-or-grid {
    display: flex;
}

.game-directions {
    font-family: Arial, sans-serif;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    opacity: .8;
    color: white;
}

.guesses-remaining {
    margin-top: 10px;
    margin-left: -130px;
    color: rgb(255, 255, 255);
}

.guesses-remaining-bubble {
    opacity: 100;
    background-color: rgb(255, 255, 255);
    width: 16px;
    height: 16px;
    border-radius: 9999px;
    margin-top: -32px;
    margin-left: 10px;
}

.guesses-container {
    margin-left: 110px;
}

.lose-guess-animation {
    animation: bounceOut;
    animation-duration: 2.2s;
    animation-delay: 2.5s;
}

.guess-invisble {
    opacity: 0;
}

.flex-center {
    display: flex;
    justify-content: center;
}

.score-container {
    margin-top: 25px;
    gap: 1px;
    margin-bottom: -10px;
}

.question-boxes {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 3px;
    background-color: white;
    cursor: pointer;
}

.stats-container {
    display: flex;
    justify-content: center;
    margin-bottom: -23px;
    gap: 6px;
    align-items: baseline;
    margin-top: 40px;
}

.stats-bar {
    width: 25px;
    height: 300px;
    border: none;
    border-radius: 10px 10px 0px 0px;
    background-color: black;
    cursor: pointer;
    transition: height 2s
}

.stat-text {
    color: black;
    font-size: 12px;
    font-weight: 400;
}

.mobile-question-boxes-container {
    display: grid;
    grid-template-columns: repeat(12, auto);
    justify-content: center;
}

.question-box-selected {
    background-color: #ffd700;
}

.color-0 {
    background-color: #DDA0DD
}

.color-1 {
    background-color: #FA8072
}

.color-2 {
    background-color: #7CB9E8
}

.color-3 {
    background-color: #71BC78
}

.color-4 {
    background-color: gray
}

.color-4-selected {
    background-color: #ffd700;
    margin-left: 10px;
}

.score {
    font-size: 20px;
    justify-content: center;
    font-weight: 550;
}

.question-box-animation {
    animation: rubberBand;
    animation-duration: 1s;
}

.grey-delay {
    opacity: .2;
    transition-delay: .6s;
}

.grey-no-delay {
    opacity: .2;
    cursor: pointer;
}

.grey-selected {
    background-color: #c5c5c5;
}

.submit {
    background-color: white;
    border: 1px solid white;
    border-radius: 25px;
    width: 115px;
    padding: 15px 20px;
    font-size: 17px;
    color: black;
    cursor: pointer;
    font-weight: 600;
    margin-top: 10px;
}

.skip-to-results {
    background-color: #aaa;
    border: none;
    border-radius: 25px;
    width: fit-content;
    padding: 15px 20px;
    font-size: 16px;
    color: white;
    cursor: pointer;
    font-weight: 600;
    margin-top: 10px;
    animation: fadeIn;
    animation-duration: 3s;
}

.gap-submit {
    margin-top: -20px;
    gap: 25px
}

.view-results-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto;
    column-gap: 10px;
}

.disable-click {
    opacity: .07;
    cursor: default;
    background-color: white;
    border: 1px solid white;
    color: black;
    font-weight: 600;
}

.submit-border {
    border: 1px solid #005A9C;
}

.submit-color {
    background-color: #005A9C;
}

.message-alert {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    color: black;
    border-radius: 10px;
    font-weight: 600;
    animation: fadeIn;
    animation-duration: .5s;
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    /* Align horizontal */
    align-items: center;
    z-index: 50;
}

.mobile-date {
    margin-left: -5px;
    margin-right: 10px;
    font-weight: 300;
}

.message-alert-fade-out {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    background-color: white;
    color: black;
    border-radius: 10px;
    font-weight: 600;
    animation: fadeOut;
    animation-duration: 1s;
    padding: 0px 20px;
}

.blur {
    opacity: .175;
    position: absolute;
    width: 100vw;
    height: 100vh;
}

.view-results {
    border: 2px solid black;
    color: black;
    background-color: white;
    border-radius: 25px;
    font-weight: 600;
    font-size: 15px;
    margin-top: 15px;
    cursor: pointer;
    width: 120px;
    padding: 15px 10px;
}

.instruction-text {
    text-align: left;
    font-size: 16px;
    word-wrap: break-word;
}

.bullet-text {
    margin-left: 20px;
}

.goal-of-game {
    font-size: 22px;
}

li {
    margin-left: 20px;
}

.lets-play {
    background-color: white;
    border-radius: 10px;
    color: black;
    padding: 10px;
    font-size: 17px;
    font-weight: 450;
    width: 170px;
    margin-top: 40px;
    border: 2px solid black;
    cursor: pointer;
}

.instructions {
    width: 450px;
    height: fit-content;
    background-color: white;
    border-radius: 10px;
    border: none;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-top: 170px;
    z-index: 10;
    box-shadow: 0 3px 12px -1px;
    padding: 15px 40px 30px 40px;
    word-wrap: break-word;
}

.instruction-header {
    font-size: 27px;
    font-weight: 700;
}

.instruction-header-1 {
    font-weight: 500;
    margin-top: 10px;
    font-size: 18px;
}

.crossword-keyboard-container {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
}

.down-arrow {
    width: 75px;
    height: 65px;
    margin-top: -5px;
    margin-left: -20px;
}

.instruction-bold {
    font-weight: 550;
}

.how-to-play-icon {
    font-size: 30px;
    position: absolute;
    right: 10%;
    margin-top: 75px;
    cursor: pointer;
    color: white;
}

.swipe-tutorial {
    position: absolute;
    font-size: 45px;
    z-index: 9;
    top: 230px;
    right: 80px;
    color: white;
    animation: slide 2s forwards;
    animation-iteration-count: infinite;
}

.mobile-game-number {
    font-size: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-weight: 200;
}

.header-container {
    display: flex;
}

@keyframes slide {
    100% {
        right: 280px;
    }
}

@media (max-width: 1200px) {
    .game-directions {
        display: none;
    }
}

@media (max-width: 1000px) {
    .flex-or-grid {
        display: grid;
        row-gap: 10px;
        grid-template-columns: auto
    }

    .game-header-name {
        margin-left: 25px;
        font-size: 40px;
    }

    .game-date {
        margin-top: 0px;
        text-align: left;
        font-size: 23px;
        margin-bottom: 10px;
    }

    .beta {
        margin-left: 5px;
        font-size: 23px;
    }
}

@media (max-width: 600px) {
    .instructions {
        width: 420px;
    }
}

@media (max-height: 750px) {
    .question-boxes {
        width: 30px;
        height: 30px;
    }

    .mobile-question-boxes-container {
        margin-top: 8px;
    }

    .swipe-tutorial {
        top: 250px;
    }

    .game-header {
        display: none;
    }
}

@media (max-height: 620px) {
    .header-container {
        display: none;
    }

    .question-boxes {
        width: 30px;
        height: 26px;
    }

    .game-directions {
        margin-top: 17px;
    }
}

@media (max-width: 430px) {
    .flex-or-grid {
        display: flex;
    }

    .stats-bar {
        width: 23.4px;
        height: 300px;
        border: none;
        border-radius: 10px 10px 0px 0px;
        cursor: pointer;
        transition: height 2s;
    }

    .stats-container {
        display: flex;
        justify-content: center;
        margin-bottom: -23px;
        gap: 6px;
        align-items: baseline;
        margin-top: -7px;
    }

    .view-results-grid {
        display: grid;
        justify-content: center;
        grid-template-columns: auto auto auto;
        padding-top: 10px;
        column-gap: 25px;
    }

    .mobile-arrow-game-over {
        color: white;
        font-size: 45px;
        margin-top: 20px;
    }

    .view-results {
        color: black;
        background-color: white;
        border-radius: 20px;
        font-weight: 600;
        font-size: 18.5px;
        cursor: pointer;
        padding: 15px 10px;
        width: 150px;
        font-weight: 700;
    }

    .view-stats {
        margin-top: 30px;
    }

    .game-header-name {
        margin-left: 0px;
        font-size: 28px;
        margin-top: 46px;
        text-align: center;
        width: 100vw
    }

    .game-date {
        display: none;
    }

    .game-directions {
        font-size: 16px;
        margin-top: -5px;
        letter-spacing: -.03ch;
        display: block;
        margin-bottom: 10px;
        font-weight: lighter;
    }

    .message-alert-message-text {
        margin-top: 7px;
        font-size: 15px;
        color: black
    }

    .instruction-text {
        font-size: 16.5px;
        line-height: 2.3ch;
    }

    .message-alert-message-text {
        font-size: 16px;
        margin-top: 15px;
    }

    .game-directions-invisible {
        margin-top: 24px;
    }

    .instructions {
        width: 79%;
        height: fit-content;
        background-color: white;
        border-radius: 10px;
        border: none;
        position: absolute;
        margin-top: 80px;
        z-index: 10;
        overflow-y: scroll;
    }

    .instruction-header {
        font-size: 23px;
        font-weight: 700;
        margin-top: 0px;
    }

    .instructions-text-container {
        margin-left: -20px;
    }

    .instruction-header-1 {
        margin-top: -15px;
        font-size: 19px;
        font-weight: 400;
    }

    .instruction-header-2 {
        padding-top: 10px;
        font-size: 18px;
        font-weight: 500;
    }

    .lets-play {
        background-color: white;
        border-radius: 10px;
        color: black;
        padding: 10px;
        font-size: 17px;
        font-weight: 450;
        width: 170px;
        margin-top: 35px;
        margin-bottom: 100px;
        border: 2px solid black;
        cursor: pointer;
    }

    .how-to-play-icon-mobile {
        font-size: 23.5px;
        position: absolute;
        right: 8.5%;
        margin-top: 50px;
        cursor: pointer;
        color: white;
    }

    .puzzle-number-mobile {
        font-size: 20px;
        position: absolute;
        left: 10%;
        margin-top: 50px;
        cursor: pointer;
        color: white;
        font-weight: 300;
    }

    .score-container {
        margin-top: 10px;
        gap: 3px;
    }

    .question-boxes {
        width: 28px;
        height: 28px;
        margin-left: -1.5px;
    }

    .play-newest-puzzle-button {
        width: 120px;
        font-size: 13px;
        padding: 0px;
        height: 24px;
        margin-top: 20px;
        border-radius: 5px;
        margin-right: 20px;
    }

    .game-header {
        display: block;
        border-bottom: none;
    }
}

@media (max-width: 320px) {
    .question-boxes {
        width: 23px;
        height: 23px;
    }

    .stats-container {
        margin-top: 25px;
    }

    .view-results {
        border: 2px solid black;
        color: black;
        background-color: white;
        border-radius: 25px;
        font-weight: 600;
        font-size: 15px;
        margin-top: 15px;
        cursor: pointer;
        width: 120px;
        padding: 15px 10px;
    }
}
.mobile-word {
    max-width: 50px;
    height: 50px;
    border-left: 1px solid white;
    border-top: 3px solid white;
    border-bottom: 3px solid white;
    font-size: 50px;
    color: black;
    font-weight: 700;
    background-color: #87CEFA;
    position: relative;
    z-index: 10;
}


.word {
    width: 75px;
    height: 70px;
    border-left: 1px solid white;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    border-bottom: 3px solid rgba(255, 255, 255, 0.8);
    text-align: center;
    font-size: 50px;
    color: black;
    font-weight: 500;
    margin-top: 10px;
    background-color: #a7d8ff;
    cursor: pointer;
}

.mobile-word-active {
    background-color: #ffda00;
}

.far-left-mobile {
    border-left: 3px solid rgba(255, 255, 255, 0.8);
    margin-left: 5px;
}

.far-right {
    border-right: 3px solid rgba(255, 255, 255, 0.8);
    margin-right: 5px;
}

.mobile-letter {
    font-size: 30px;
    position: relative;
    top: -12px;
}

.letter {
    font-size: 50px;
    text-align: center;
    top: 5px;
    position: relative;
}

.background-white {
    background-color: white;
}

.crossword-correct-animation {
    animation: tada;
    animation-duration: 2s;
    animation-delay: 2s;
    position: relative;
    z-index: 20;
}

.crossword-incorrect-animation {
    animation: shakeX;
    animation-duration: .5s;
    animation-delay: 2s;
    position: relative;
    z-index: 20;
}

.reveal-crossword-animation {
    animation: tada;
    animation-duration: 2s;
    animation-delay: 0s;
    position: relative;
    z-index: 20;
}

@media (max-height: 650px) {
    .mobile-word {
        width: 45px;
        height: 42px;
        margin-top: -15px;
    }

    .mobile-letter {
        font-size: 27px;
        position: relative;
        top: -16px;
    }
}

@media (max-height: 610px) {
    .mobile-word {
        max-width: 35px;
        height: 32px;
    }

    .mobile-letter {
        font-size: 24px;
        position: relative;
        top: -22px;
    }
}
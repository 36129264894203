.pop-up {
    width: 500px;
    padding-bottom: 20px;
    border: none;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    box-shadow: rgba(0, 0, 0, 0.19) 3px 6px 6px,
        rgba(0, 0, 0, 0.2) 3px 3px 3px;
    background-color: white;
    margin-top: 30px;
    animation: fadeIn;
    animation-duration: .3s;
    top: 75px;
}

.next-time-pop-up-header-text {
    color: black;
    margin-top: 30px;
    font-weight: 600;
    font-size: 32px;
    font-family: karnakCondensedBold;
    letter-spacing: -.05rem;
    padding: 5px;
}

.puzzle-number {
    font-size: 23px;
    font-weight: 500;
    margin-top: -35px;
    font-family: NYTFranklin;
    letter-spacing: -.05rem;
}

.score-font {
    color: black;
    margin-top: -15px;
    font-weight: 300;
    font-size: 22px;
    font-family: karnakCondensedBold;
}

.next-puzzle-in {
    font-size: 20px;
    font-family: NYTFranklin;
    margin-top: 20px;
    margin-bottom: 10px;
}

.time-until-next-puzzle {
    font-size: 35px;
    font-family: NYTFranklin;
}

.share-button {
    width: 175px;
    height: 52px;
    background-color: black;
    border: none;
    border-radius: 200px;
    color: white;
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
}

.share-results-button {
    width: 130px;
    height: 50px;
    background-color: black;
    border: none;
    border-radius: 200px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    color: white;
    font-size: 16px;
    padding-left: 15px;
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
    border-right: 1px solid white
}

.play-yesterdays-game {
    height: 50px;
    background-color: #662d91;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 5px;
    border-right: 1px solid white;
    width: 320px;
}

.play-yesterdays-game-selected {
    height: 50px;
    background-color: #662d91;
    border: none;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: gold;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 5px;
    border-right: 1px solid white;
    width: 300px;
}

.play-puzzle-text {
    text-align: center;
    font-size: 18px;
    font-weight: 650;
    margin-top: 15px;
    width: 215px;
    margin-left: 65px;
}

.select-puzzle-number {
    background-color: #662d91;
    color: white;
    font-size: 20px;
    border: none;
    font-weight: 650;
    cursor: pointer;
    height: 50px;
    margin-top: 20px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-left: 10px;
    padding-right: 5px;
}

.select-emoji {
    background-color: black;
    color: white;
    font-size: 20px;
    border: none;
    font-weight: 650;
    cursor: pointer;
    height: 50px;
    margin-top: 0px;
    border-top-right-radius: 200px;
    border-bottom-right-radius: 200px;
    padding-left: 10px;
    padding-right: 5px;
    margin-left: 0px;
    top: 2px;
    position: relative;
}

.close-results {
    height: 50px;
    background-color: #EE204D;
    border: none;
    border-radius: 10px;
    color: white;
    font-size: 17px;
    font-weight: 800;
    margin-left: 10px;
    cursor: pointer;
    margin-top: 30px;
    padding: 15px;
}

.share-buttons {
    margin-top: 20px;
}

.center-flex {
    display: flex;
    justify-content: center;
}

.color-grid {
    display: grid;
    justify-content: center;
    grid-template-columns: auto auto auto;
    margin-top: -25px;
}

.crossword-result {
    margin-top: 7px;
    display: grid;
    justify-content: center;
}

.share {
    background-color: #318CE7;
    font-size: 17px;
}

.grid-box {
    width: 32px;
    height: 32px;
    border-radius: 4px;
    margin: 2px .5px;
}

.grid-box-red {
    width: 20px;
    height: 20px;
    padding: 2px;
    margin: 2px .5px;
    background-color: #FA8072;
    font-size: 15px;
    text-align: center;
    color: black;
    font-weight: 550;
    line-height: 18px;
    margin-left: -2px;
    border: 1px solid black;
}

.grid-box-green {
    width: 20px;
    height: 20px;
    padding: 2px;
    margin: 2px .5px;
    font-size: 15px;
    text-align: center;
    color: black;
    font-weight: 550;
    line-height: 18px;
    margin-left: -2px;
    background-color: #9FE6A0;
    border: 1px solid black;
}

.grid-box-gray {
    background-color: #EFEFE6;
}

.grid-box-0 {
    background-color: #DDA0DD;
}

.grid-box-1 {

    background-color: #FF6347;
}

.grid-box-2 {
    background-color: #7CB9E8;
}

.grid-box-3 {
    background-color: #71BC78;
}

.close-pop-up {
    position: absolute;
    right: 15px;
    top: 10px;
    font-size: 25px;
    cursor: pointer;
    color: black;
}

.close-pop-up:hover {
    opacity: .7;
}

.make-your-own-puzzle-link {
    margin-top: 20px;
    text-decoration: underline;
    color: #004CC4;
}

@media (max-width: 1200px) {
    .pop-up {
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
        padding-bottom: 30px;
        border: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 10;
        background-color: white;
        padding-top: none;
        margin-top: 0px;
    }

    .next-time-pop-up-header-text {
        font-size: 40px;
        margin-top: 15px;
    }
}

@media (max-width: 430px) {
    .pop-up {
        width: 100vw;
        height: 100vh;
        border-radius: 0px;
        padding-bottom: 30px;
        border: none;
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        z-index: 10;
        background-color: black;
    }

    .next-time-pop-up-header-text {
        font-size: 30px;
        margin-top: 15px;
        color: white;
    }

    .next-puzzle-in {
        color: white;
    }

    .time-until-next-puzzle {
        font-size: 35px;
        font-family: NYTFranklin;
        color: white;
    }

    .share-results {
        background-color: #318CE7;
        color: white;
    }

    .make-your-own-puzzle-link {
        color: white;
        margin-top: 12px;
    }

    .close-pop-up {
        color: white;
        margin-top: -15px;
        margin-right: 15px;
    }

    .close-pop-up-instructions {
        color: white;
        margin-top: -15px;
        margin-right: 15px;
        position: absolute;
        right: 5px;
        top: 20px;
        font-size: 25px;
        cursor: pointer;
    }

    .black {
        color: black;
    }

    .select-emoji-mobile {
        background-color: #318CE7;
        margin-top: 2px;
        border-left: .5px solid white;
        width: 62px;
    }

    .share-results-button {
        font-size: 14.5px;
        padding-right: 105px;
        white-space: nowrap;
        margin-top: 4px;
        width: 115px;
    }

    .share {
        background-color: white;
        color: black;
        font-size: 16px;
        width: 175px;
        margin-top: 2px;
    }
}

@media (max-width: 320px) {

    .grid-box {
        width: 25px;
        height: 25px;
        border-radius: 4px;
        margin: 2px .5px;
    }

    .close-pop-up {
        color: white;
        margin-top: 5px;
        margin-right: 0px;
    }

    .next-puzzle-in {
        display: none;
    }

    .time-until-next-puzzle {
        display: none;
    }

    .make-your-own-puzzle-link {
        display: none;
    }

    .select-emoji {
        display: none;
    }

    .share-results-button {
        border-right: none;
        border-radius: 200px;
        width: 170px;
        margin-left: 0px;
        padding: 0px;
        text-align: center;
    }

    .play-yesterdays-game {
        width: 250px;
    }

    .play-yesterdays-game-selected {
        width: 250px;
    }

    .play-puzzle-text {
        margin-left: 35px;
    }
}
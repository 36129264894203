.keyboard-container {
    width: 100vw;
    height: 205px;
    bottom: 0px;
    position: absolute;
    z-index: 10;
}

.inner-keyboard-container {
    padding: 12px 10px;
    margin-top: -24px;
}

.keyboard-row {
    display: flex;
    justify-content: center;
    gap: 5.5px
}

.skip-to-results-mobile {
    font-size: 15px;
    color: white;
    margin-top: 5px;
    border-radius: 5px;
    padding: 4px 20px;
    border: none;
    background-color: #aaa;
    font-weight: 600;
}

.keyboard-key {
    background-color: #535353;
    color: white;
    min-width: 22px;
    height: 35px;
    padding: 5px 6px;
    border-radius: 5px;
    font-weight: 400;
    box-shadow: none;
    align-items: center;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.keyboard-key-no-active {
    background-color: #535353;
    color: white;
    min-width: 22px;
    height: 35px;
    padding: 5px 6px;
    border-radius: 5px;
    font-weight: 400;
    box-shadow: none;
    align-items: center;
    font-size: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 20;
}

.keyboard-key:active {
    height: 85px;
    top: -50px;
    position: relative;
    margin-bottom: -50px;
}

.keyboard-key:active .keyboard-letter {
    margin-top: -45px;
    font-size: 30px;
    font-weight: 700;
}

.keyboard-row-top {
    margin-top: 28px;
}

.keyboard-row-middle {
    margin-top: 10px;
}

.middle-key {
    margin-left: 1px;
}

.keyboard-row-bottom {
    margin-top: 10px;
}

.keyboard-key-enter {
    background-color: #2F2F2F;
    font-size: 16px;
    padding: 5px 2px;
    letter-spacing: -0.05ch;
    font-weight: 650;
    margin-right: 5px;
    padding: 5px 14px;
}


.keyboard-key-delete {
    font-size: 21px;
    padding: 5px 0px;
}

.delete-background-color {
    background-color: #2F2F2F;
    margin-left: 5px;
    padding: 5px 10px;
}

.arrow-left-mobile {
    margin-top: 0px;
    margin-top: 5px;
    color: white;
    position: absolute;
    bottom: 5px;
    right: 65px;
    font-size: 25px;
}

.arrow-right-mobile {
    margin-top: 0px;
    margin-top: 5px;
    color: white;
    position: absolute;
    bottom: 5px;
    right: 25px;
    font-size: 25px;
}

.arrow-disabled {
    opacity: .2;
}

.guesses-remaining-mobile {
    margin-top: 0px;
    margin-left: -130px;
    margin-top: 5px;
    color: white;
}

.mobile-bubble {
    margin-top: -33px !important
}

.mobile-score {
    font-size: 17px;
    margin-top: 0px;
    font-weight: 550;
    text-align: center;
    background-color: white;
    width: 100%;
    padding: 4px;
}

.mobile-score-number {
    display: inline-block;
    width: 20px;
    text-align: right;
    margin-left: -2px;
    margin-right: 1px;
}

.subtracted-value-present-mobile {
    font-size: 18px;
    animation: fadeInLeft;
    animation-duration: .7s;
    font-weight: 600;
    position: absolute;
    margin-top: 3.5px;
    left: 250px
}

.y {
    display: none;
}

@media (max-width: 380px) {
    .keyboard-container {
        height: 170px
    }

    .keyboard-key {
        min-width: 16px;
        font-size: 18px;
        height: 26px;
    }

    .keyboard-key-enter {
        font-size: 13px;
        margin-right: 0px;
        padding: 5px 9px;
    }

    .delete-background-color {
        font-size: 20px;
        margin-left: 0px;
        padding: 5px 8px;
    }

    .arrow-left-mobile {
        margin-top: 5px;
        color: white;
        position: absolute;
        bottom: 3px;
        right: 35px;
        font-size: 21px;
    }


    .arrow-right-mobile {
        margin-top: 5px;
        color: white;
        position: absolute;
        bottom: 3px;
        right: 5px;
        font-size: 21px;
    }
}
.clueTable {
    display: grid;
    border-color: black;
    grid-template-columns: auto auto auto auto auto;
    justify-content: center;
    row-gap: 10px;
    column-gap: 10px;
    margin-top: 20px;
}

.span-filler {
    height: 25px
}

@media (max-width: 430px) {
    .clueTable {
        column-gap: 3px;
        row-gap: 3px;
        margin-top: -10px;
    }
}
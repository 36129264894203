.chatgpt-container {
    width: 500px;
    height: 165px;
    background-color: none;
    border: 1px solid white;
    border-radius: 20px;
    margin-top: 15px;
    box-shadow: 0 20px 25px -5px #000, 0 8px 10px -6px #000;
    color: white;
    font-size: 17px;
}

.chatgpt-text {
    padding: 20px 10px 20px 20px;
    text-align: left;
}

.cursor {
    font-weight: 100;
    font-size: 23px;
    color: white;
    margin-top: -6px;
    -webkit-animation: 1s blink step-end infinite;
    -moz-animation: 1s blink step-end infinite;
    -ms-animation: 1s blink step-end infinite;
    -o-animation: 1s blink step-end infinite;
    animation: 1s blink step-end infinite;
}

@keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: white;
    }
}

@-moz-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: white;
    }
}

@-webkit-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: white;
    }
}

@-ms-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: white;
    }
}

@-o-keyframes blink {

    from,
    to {
        color: transparent;
    }

    50% {
        color: white;
    }
}
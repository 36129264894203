.overlay-container {
    background-color: black;
    width: 100%;
    height: 110%;
    position: absolute;
    z-index: 1000;
}

.overlay-container-fade-out {
    background-color: black;
    width: 100%;
    height: 110%;
    position: absolute;
    z-index: 100;
    animation: fadeOut;
    animation-duration: 1s;
}

.content-container {
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}


.logo {
    width: 300px;
    height: 300px;
    margin-top: -10px;
}

.fade-in-up {
    animation: fadeInUp;
    animation-duration: 4s;
}

.game-title {
    font-family: "nyt-karnakcondensed";
    font-size: 50px;
    font-weight: bolder;
    text-align: center;
    color: white;
    margin-top: 20px;
}

.game-slogan {
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    margin-top: -30px;
    color: white;
}

.play-button {
    width: 150px;
    padding: 20px;
    border: none;
    background-color: white;
    color: black;
    border-radius: 30px;
    font-size: 17px;
    margin-top: 55px;
    cursor: pointer;
}

.cover-date {
    margin-top: 45px;
    font-weight: 700;
    font-size: 17px;
    color: white;
}

.author {
    margin-top: -10px;
    font-weight: 300;
    color: white;
}

.make-puzzle {
    margin-top: 10px;
    font-weight: 300;
    cursor: pointer;
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    color: white;
}

.make-puzzle-anchor {
    color: white;
    text-decoration: none;
}

@media (max-width: 430px) {

    .logo {
        width: 275px;
        height: 275px;
        margin-top: 0px;
    }

    .cover-date {
        margin-top: 40px;
        font-weight: 400;
    }

    .author {
        margin-top: -15px;
        font-weight: 100;
    }

    .game-title {
        font-size: 40px;
        margin-top: 5px;
    }

    .game-slogan {
        font-size: 17px;
        font-weight: 100;
        line-height: 25px;
    }

    .play-button {
        width: 150px;
        padding: 15px 20px;
        font-size: 22px;
        margin-top: 30px;
        font-weight: 500;
    }

    .make-puzzle {
        margin-top: 4px;
    }

    .overlay-container {
        height: 112%;
    }
}